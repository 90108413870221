import React from 'react'
import { graphql, PageProps } from 'gatsby'
//Types
import { PageInfoProps, PublicationProps } from '../types/pages'
import { SectionBase } from '../types/sections'
// Components
import { Layout, Seo } from '../components/commons'
import Section from '../components/sections'

interface DataQuery {
  datoCmsHome: {
    pageInfo: PageInfoProps
    locale: string
    meta: PublicationProps
    sections: SectionBase[]
  }
}

const IndexPage: React.FC<PageProps<DataQuery>> = ({ data }) => {
  const {
    datoCmsHome: { meta, locale, pageInfo, sections },
  } = data

  return (
    <Layout headerDark={pageInfo.headerDark}>
      <Seo slug={''} publication={meta} lang={locale} content={pageInfo.seo} />
      {sections.map((s: SectionBase) => s?.model && <Section key={s.id} sectionApiKey={s.model.apiKey} data={s} />)}
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexQuery {
    datoCmsHome {
      locale
      meta {
        firstPublishedAt
        publishedAt
        updatedAt
      }
      pageInfo {
        ...pageInfoFragment
      }
      sections {
        ...sectionHomeHeroFragment
        ...sectionFeatureSimpleFragment
        ...sectionLogoWallFragment
        ...sectionTestimonialFragment
        ...sectionBlogFragment
        ...sectionVideoFragment
        ...sectionPodcastFragment
        ...sectionFeaturesListFragment
      }
    }
  }
`
